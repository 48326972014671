<template>
  <div class="LocaleSwitcher">
    <div class="sel">
        <input v-model="$i18n.locale" id="el" type="radio"  value="el" v-on:click="$i18n.locale=el" />
        <label class="flag el" for="el"></label>
        <input v-model="$i18n.locale" id="en" type="radio"  value="en" v-on:click="$i18n.locale=en" />
        <label class="flag en" for="en"></label>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  data () {
    return { langs: ['el', 'en'] }
  }
}
</script>

<style>
.LocaleSwitcher {
            display: inline;
            position: absolute;
            top: 0px;
            right: 0px;
            float: right;
        }

        .sel input{
    margin:0;padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}



.el{background-image: url(../assets/flag/el.png);}
.en{background-image:url(../assets/flag/en.png);}

.sel-2 input:active +.flag, .sel input:active +.flag{opacity: .9;}
.sel-2 input:checked +.flag, .sel input:checked +.flag{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}
.flag{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
    width:30px;height:20px;
    -webkit-filter:  grayscale(1);
       -moz-filter:  grayscale(1);
            filter:  grayscale(1);
}
.flag:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
            filter: brightness(1.2) grayscale(.5) opacity(.9);
}
</style>
