<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row">
                <div class="text-center col-md-7 col-lg-5 mb-5 mb-md-0">
                    <p>{{ $t('dr') }}</p>
                    <div class="card card-lift--hover shadow border-0">
                        <a target="_blank" href="https://drfix.gr" title="DrFix">
                            <img v-lazy="'img/theme/drfix.jpg'" class="card-img">
                        </a>
                    </div>
                </div><!--
                <div class="col-md-6 mb-5 mb-lg-0">
                    <div class="card card-lift--hover shadow border-0">
                        <router-link to="/profile" title="Profile Page">
                            <img v-lazy="'img/theme/profile.jpg'" class="card-img">
                        </router-link>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-6">
                    <h6 class="text-primary font-weight-light mb-2">{{ $t('wr1') }}</h6>
                    <p font-size="6px" class="mb-0 font-weight-light small">{{ $t('wr2') }}</p>
                </div>
                <div class="col-lg-6 text-lg-center btn-wrapper">
                    <a target="_blank" rel="noopener" href="https://www.facebook.com/DrFixService"
                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Like us">
                        <i class="fa fa-facebook-square"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        DrFix &copy; {{year}}. {{ $t('created') }}
                        <a href="https://gerstavros.com/" target="_blank" rel="noopener">{{ $t('stavros') }}</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <a href="https://drfix.gr" class="nav-link" target="_blank" rel="noopener">DrFix Group</a>
                        </li>
                        <li class="nav-item">
                            <router-link to="/contact"><a class="nav-link"  rel="noopener">{{ $t('ab') }}</a></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/repair"><a class="nav-link" target="_blank" rel="noopener">{{ $t('sendrep') }}</a></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>

<i18n>
{
  "en": {
     "dr": "Visit our main site and eshop",
     "wr1": "Caution! We are not official Xiaomi Repair Service.",
     "wr2": "If you are searching for warranty service, please contact the shop you bought from. We don't handle any shop's warranty repairs. However all our repairs are covered by our own warranty.",
     "created": "Design & Creation: ",
     "stavros": "Gerolymatos Stavros",
     "sendrep": "Send/Bring for repair",
    "ab": "Contact Us"
  },
  "el": {
     "dr": "Επισκεφθείτε την κεντρική μας σελίδα-eshop",
     "wr1": "Προσοχή! Δεν είμαστε επίσημο κέντρο επισκευής Xiaomi.",
     "wr2": "Εάν αναζητάτε κάλυψη της εγγύησής σας, παρακαλούμε επικοινωνήστε με το κατάστημα όπου κάνατε την αγορά. Δεν αναλαμβάνουμε υπηρεσίες εγγύησης κανενός καταστήματος ως επί το πλείστον. Ωστόσο όλες οι επισκευές μας συνοδεύονται από δική μας εγγύηση.",
     "created": "Σχεδίαση & Δημιουργία: ",
     "stavros": "Γερολυμάτος Σταύρος",
     "sendrep": "Στείλτε/Φέρτε επισκευή",
     "ab": "Επικοινωνία"
  }
}
</i18n>
