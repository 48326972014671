<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-8">
                                <h1 class="display-3  text-white">{{ $t('wel') }}
                                    <span>Xiaomi Smartphones!</span>
                                </h1>
                                <p class="lead  text-white">{{ $t('t1') }}</p>
                                <div id="serv" class="btn-wrapper">
                                    
                                    <base-button tag="a"
                                                 href="#serv"
                                                 class="mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="fa fa-info-circle"
                                                 v-smooth-scroll>
                                        {{ $t('serv') }}
                                    </base-button>
                                    
                                    <base-button tag="a"
                                                 href="#why"
                                                 class="mb-3 mb-sm-0"
                                                 type="white"
                                                 icon="fa fa-check-circle"
                                                 v-smooth-scroll>
                                        {{ $t('why') }}
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <img v-lazy="'img/theme/prob.jpg'"
                                    class="rounded-circle img-center img-fluid  shadow shadow-lg--hover"
                                    style="width: 100px;"><br>
                                    
                                    <h6 class="text-primary">{{ $t('prob') }}</h6>
                                    <p class="description mt-3">{{ $t('prob0') }}</p>
                                    <div>
                                        <badge type="primary" rounded>δεν ανάβει</badge>
                                        <badge type="primary" rounded>δε φορτίζει</badge>
                                        <badge type="primary" rounded>δεν έχει σήμα</badge>
                                    </div>
                                    <router-link to="/contact"><base-button tag="a" type="primary" style="color:#fff" class="mt-4">
                                        {{ $t('learncost') }}
                                    </base-button></router-link>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <img v-lazy="'img/theme/hardware.jpg'"
                                    class="rounded-circle img-center img-fluid  shadow shadow-lg--hover"
                                    style="width: 100px;"><br>
                                    <h6 class="text-success text-uppercase">{{ $t('hard') }}</h6>
                                    <p class="description mt-3">{{ $t('hard0') }}</p>
                                    <div>
                                        <badge type="success" rounded>αντικατάσταση οθόνης</badge>
                                        <badge type="success" rounded>επισκευή φόρτισης</badge>
                                        <badge type="success" rounded>αλλαγή μπαταρίας</badge>
                                        <badge type="success" rounded>επισκευή ηχείου ακουστικού</badge>
                                        <badge type="success" rounded>αλλαγή μικροφώνου</badge>
                                        <badge type="success" rounded>αλλαγή πλάτης</badge>
                                    </div>
                                    <router-link to="/contact"><base-button tag="a" type="success" style="color:#fff" class="mt-4">
                                        {{ $t('learncost') }}
                                    </base-button></router-link>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <img v-lazy="'img/theme/software.jpg'"
                                    class="rounded-circle img-center img-fluid  shadow shadow-lg--hover"
                                    style="width: 100px;"><br>
                                    <h6 class="text-warning text-uppercase">{{ $t('soft') }}</h6>
                                    <p class="description mt-3">{{ $t('soft0') }}</p>
                                    <div>
                                        <badge type="warning" rounded>MIUI rom</badge>
                                        <badge type="warning" rounded>Bootloop</badge>
                                        <badge type="warning" rounded>ξεκλείδωμα bootloader</badge>
                                        <badge type="warning" rounded>ξεκλείδωμα mi account</badge>
                                        <badge type="warning" rounded>ξεκλείδωμα google account frp</badge>
                                        <badge type="warning" rounded>android update</badge>
                                    </div>
                                    
                                    <router-link to="/contact">
                                    <base-button id="why" style="color:#fff" tag="a" type="warning" class="mt-4">
                                        {{ $t('learncost') }}
                                    </base-button></router-link>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="section section section-shaped my-2 overflow-hidden">
            <div class="shape shape-style-3 bg-gradient-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                        </div>
            <div class="container py-0">
                <div class="row justify-content-center text-center mb-lg">
                    <div class="col-lg-12">
                        
                        <h2 class="display-3">{{ $t('wh2') }}<br>{{ $t('wh22') }}</h2>
                        <p class="lead text-muted">{{ $t('whytext')}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xl-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img v-lazy="'img/theme/quality.svg'"
                                 class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                 style="width: 200px;">
                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">{{ $t('ourqual')}}</span>
                                    <small class="h6 text-muted">{{ $t('ourqual1')}}</small>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img v-lazy="'img/theme/fastserv.svg'"
                                 class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                 style="width: 200px;">
                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">{{ $t('fastserv')}}</span>
                                    <small class="h6 text-muted">{{ $t('fastserv1')}}</small>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img v-lazy="'img/theme/lowprice.svg'"
                                 class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                 style="width: 200px;">
                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">{{ $t('lowprice')}}</span>
                                    <small class="h6 text-muted">{{ $t('lowprice1')}}</small>
                                </h5>
                              </div>
                            </div>
                        </div>
                    <div class="col-md-6 col-xl-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img v-lazy="'img/theme/specialexp.svg'"
                                 class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                 style="width: 200px;">
                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">{{ $t('specialexp')}}</span>
                                    <small class="h6 text-muted">{{ $t('specialexp1')}}</small>
                                </h5>
                            </div>
                        </div>
                    </div></div>
            </div>
        </section>
        
        
        <section class="section section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-1 bg-gradient-warning shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container py-0">
                <div class="row row-grid align-items-center">
                    
                    <div class="col-lg-12 order-lg-1">
                        <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="fa fa-search" gradient="warning" color="white" shadow
                                          rounded></icon>
                                </div>
                                <div class="pl-12">
                                    <h3 class="title text-warning">{{ $t('sear1')}}</h3>
                                    <p>{{ $t('sear2')}}</p>
                                </div>
                            </div>
                            <form method="post" v-on:submit.prevent="makeSearch">
                            <div class="search-wrapper col-lg-12 ml-lg-12">
                                        <input v-on:success="makeSearch()" class="form-control" type="text" v-model="searchQuery" name="searstring" id="searchQuery" placeholder="Search" />
                            </div>  
                             <div class="sbtn">       <base-button type="success" v-on:click="makeSearch()">{{ $t('searbtn')}}</base-button>
                             </div>
                             </form>
                        </card>
                    </div>
                </div>
            </div>
        </section>
        
        
    </div>
</template>

<style scoped>
.pl-12 {width: 100%; text-align:center; margin-left: -48px;}
.sbtn  {width: 100%; text-align:center; padding-top: 10px;}
</style>

<script>
export default {
  name: "home",
  components: {},
  
  
    methods: {
        makeSearch: function() {
                    window.open('https://drfix.gr/index.php?route=product/search&search=' + this.searchQuery);
            }
            }
};
</script>

<i18n>
{
  "en": {
    "wel": "Specialised repair service for",
     "t1" : "We have top experience in hardware and software on Xiaomi phones since 2013",
     "serv": "Our Services",
     "why": "Our Quality",
     "wh2": "Reasons to choose our",
     "wh22": "Repair Team",
     "prob": "We repair any damage/failure",
     "prob0": "Your Mi phone doesn't open? No sound? No touch? Can't make calls, or connect to internet? Any problem you have, we will fix it if it's fixable.",
     "hard": "Hardware Repair",
     "hard0": "Replacement of any broken component, such as broken screen, broken back cover or frame, damaged speaker, earphone, microphone, old battery, charging port/USB, etc.",
     "soft": "Software Fix",
     "soft0": "Repair of any software problem. Reinstallation of the Operating System/Firmware even in locked devices. Bootloader unlocking, Custom roms, Root, TWRP recovery, EDL authorization, FRP unlock for the owner, Mi Account unlock for the owner and others.",
     "learncost": "Ask the cost",
     "whytext": "Our company makes every effort to keep you completely satisfied with our services.",
     "ourqual": "Quality",
     "fastserv": "Immediate service",
     "lowprice": "Low Prices",
     "specialexp": "Specialization/Experience",
     "ourqual1": "Parts of high Quality, Careful work",
     "fastserv1": "Repairing even the same day or in some minutes",
     "lowprice1": "Reasonable and competitive prices",
     "specialexp1": "Well-trained staff, years of experience",
     "sear1": "Search for cost",
     "sear2": "Type your model to search for parts and repair costs",
     "searbtn": "Go"
  },
  "el": {
     "wel": "Εξειδικευμένο service",
     "t1" : "Διαθέτουμε κορυφαία εμπειρία πάνω στα ανταλλακτικά και το λογισμικό των Xiaomi κινητών από το 2013",
     "serv": "Οι Υπηρεσίες μας",
     "why": "Η Ποιότητά μας",
     "wh2": "Οι λόγοι για να επιλέξετε την",
     "wh22": " Ομάδα Επισκευών μας",
     "prob": "Επισκευή κάθε βλάβης/προβλήματος",
     "prob0": "Το Xiaomi κινητό σου δεν ανοίγει; Δεν έχει ήχο; Δεν λειτουργεί η αφή; Δεν κάνει κλήσεις, ή δε συνδέεται στο διαδίκτυο; Ό,τι πρόβλημα και να έχει, μπορούμε να το επισκευάσουμε εφόσον είναι εφικτό.",
     "hard": "Επισκευή Υλικού",
     "hard0": "Αντικατάσταση οποιουδήποτε εξαρτήματος έχει πρόβλημα, όπως σπασμένης οθόνης, σπασμένης πλάτης ή πλαισίου, χαλασμένου ηχείου, ακουστικού, μικροφώνου, μπαταρίας που δεν κρατάει, βύσματος φόρτισης/USB κλπ.",
     "soft": "Επιδιόρθωση Λογισμικού",
     "soft0": "Επιδιόρθωση οποιουδήποτε προβλήματος λογισμικού. Eπανεγκατάσταση του λογισμικού ακόμη και σε κλειδωμένες συσκευές. Ξεκλείδωμα bootloader, Custom roms, Root, TWRP recovery, EDL authorization, FRP unlock κατόχου, Mi Account unlock κατόχου και άλλα.",
     "learncost": "Ρωτήστε κόστος",
     "whytext": "Η εταιρία μας καταβάλει κάθε προσπάθεια ώστε να μείνετε απόλυτα ικανοποιημένοι από τις υπηρεσίες μας.",
     "ourqual": "Ποιότητα",
     "fastserv": "Άμεση εξυπηρέτηση",
     "lowprice": "Χαμηλές τιμές",
     "specialexp": "Εξειδίκευση/Εμπειρία",
     "ourqual1": "Ανταλλακτικά υψηλής ποιότητας, Προσεκτική εργασία",
     "fastserv1": "Επισκευή ακόμη και την ίδια μέρα, ή σε μερικά λεπτά",
     "lowprice1": "Λογικές και ανταγωνιστικές τιμές",
     "specialexp1": "Άρτια εκπαιδευμένο προσωπικό, χρόνια εμπειρία",
     "sear1": "Αναζήτηση κόστους",
     "sear2": "Πληκτρολογήστε το μοντέλο του Xiaomi σας για να βρείτε ανταλλακτικά και κόστος επισκευής",
     "searbtn": "Πάμε"
  }
}
</i18n>
