<template>
<div id="header">
    <LocaleSwitcher/>
    <div id="nav" class="navbar-main navlg mr-lg-12" transparent>
      <router-link to="/"><img src="img/logo.png" height="30px" alt="logo" title="XiaomiServiceHellas"></router-link> &nbsp; &nbsp; &nbsp; 
      <router-link id="mnlnk" to="/">{{ $t('ser') }}</router-link>|
      <router-link id="mnlnk" to="/contact">{{ $t('ab') }}</router-link>|
      <router-link id="mnlnk" to="/repair">{{ $t('rep') }}</router-link>
    </div>
    <div id="nav" class="navbar-main navsm mr-lg-12" transparent>
      <router-link to="/"><div class="col-md-3 col-12"><img src="img/logo.png" height="30px" alt="logo" title="XiaomiServiceHellas"> </div></router-link> &nbsp; &nbsp; &nbsp; 
      <router-link id="mnlnk" to="/">{{ $t('ser') }}</router-link>|
      <router-link id="mnlnk" to="/contact">{{ $t('ab') }}</router-link>|
      <router-link id="mnlnk" to="/repair">{{ $t('rep') }}</router-link>
    </div>
</div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: 'header',
  components: {
    LocaleSwitcher
  }
,
mounted() {
    window.onscroll = function() {myFunction()};
    var header = document.getElementById("header");
    var sticky = header.offsetTop;
    function myFunction() {
        if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}
}};

</script>
<style>
.navbar-main {background: #fff;}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

#mnlnk {padding: 10px;}

@media screen and (max-width: 600px) {
  .navlg {
    display: none;
  }
}
@media screen and (min-width: 601px) {
  .navsm {
    display: none;
  }
}

</style>

<i18n>
{
  "en": {
    "ser": "Our Services",
    "ab": "Contact Us",
    "rep": "Send a repair"
  },
  "el": {
     "ser": "Υπηρεσίες",
     "ab": "Επικοινωνία",
     "rep": "Αποστολή επισκευής"
  }
}
</i18n>
